import Vue from 'vue'

// eslint-disable-next-line import/no-extraneous-dependencies
const moment = require('moment')

const locales = {
  en: 'en-gb',
  ua: 'uk',
  ru: 'ru',
  pl: 'pl',
}
const locale = localStorage.getItem('language') || 'en'
require(`moment/locale/${locales[locale]}`)

Vue.use(require('vue-moment'), {
  moment,
})
