// PL
export const locale = {
  BASE: {
    BROWSE: 'wybierz z urządzenia',
    DROP_HERE_OR: 'Przeciągnij tutaj lub',
    TOTAL: 'razem',
    ERRORS: 'Błędy',
    SEARCH: 'Szukaj',
    PREVIEW: 'Podgląd',
    DELETE: 'Usuń',
    NOT_SELECTED: 'Nie wybrano',
    BACK: 'Wstecz',
    ACTIONS: 'Działania',
    YES: 'Tak',
    NO: 'Nie',
    LOADING_TABLE: 'Ładowanie...',
    EMPTY_DATA: 'Brak odpowiednich zapisów...',
    NO_DATA: 'Brak wyboru',
    CRITERIA: 'Filtr',
    EDIT: 'Edytuj',
  },
  USER_PROFILE: {
    TITLE: 'Profil użytkownika',
    HI: 'Cześć',
  },
  CLIPBOARD: {
    COPY_SUCCESS: 'Skopiowano',
  },
  TOAST: {
    CREATED: 'Rekord utworzony',
    EDITED: 'Rekord edytowany',
    ERROR: 'Błąd',
    SUCCESS: 'Sukces!',
  },
  VALIDATION: {
    INVALID: '{name} jest nieprawidłowy',
    REQUIRED: '{name} jest polem wymaganym',
    MIN_LENGTH: '{name} minimalna długość to {min}',
    AGREEMENT_REQUIRED: 'Akceptacja warunków jest wymagana',
    NOT_FOUND: 'Żądany {name} nie został znaleziony',
    INVALID_LOGIN: 'Dane logowania są nieprawidłowe',
    REQUIRED_FIELD: 'Pole wymagane',
    MIN_LENGTH_FIELD: 'Minimalna długość pola:',
    MAX_LENGTH_FIELD: 'Maksymalna długość pola:',
    INVALID_FIELD: 'Pole jest nieprawidłowe',
    MAX_SIZE_FILE: 'Maksymalny rozmiar przesyłanego pliku: {size} Mb',
    MAX_COUNT_FILE: 'Dozwolone tylko {count} dokumentów/zdjęć',
    ORDER: {
      quantity: 'Ilość',
      service: 'Usługa',
      CANT_ADD_SERVICE_WITHOUT_PRICE: 'Wybrano usługę bez aktualnej ceny',
      CANT_ADD_PRODUCT_WITHOUT_PRICE: 'Wybrano produkt bez aktualnej ceny',
    },
  },
  DATES: {
    TODAY: 'Dziś',
    MONTH: 'Miesiąc',
    YEAR: 'Rok',
    YESTERDAY: 'Wczoraj',
    LAST_MONTH: 'Ostatni miesiąc',
    DAYS_OF_WEEK: {
      SU: 'Niedziela',
      MO: 'Poniedziałek',
      TU: 'Wtorek',
      WE: 'Środa',
      TH: 'Czwartek',
      FR: 'Piątek',
      SA: 'Sobota',
    },
    DAYS_OF_WEEK_ARRAY: [
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
      'Niedziela',
    ],
  },
  FORM: {
    LOCALE: 'Lokalizacja',
    KEY: 'Klucz',
    TEXT: 'Tekst',
    MAILER_FROM_EMAIL: 'Adres e-mail',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE: 'Przedłużenie ceny na X dni przed',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT: 'Przedłużenie ceny na X dni',
    PROLONGATE_SERVICE_PRICES_DAYS_BEFORE_HINT: 'Ile dni przed końcem przedłużyć ceny',
    PROLONGATE_SERVICE_PRICES_DAYS_COUNT_HINT: 'Na ile dni przedłużyć ceny',
    ACTIVE_TELEGRAM_BOT: 'Aktywny bot telegram',
    CURRENCY_PROVIDERS: 'Źródła kursów walut',
    TOKEN: 'Token',
    SUBMIT: 'Potwierdź',
    CLEAR: 'Wyczyść',
    LOGO: 'Logo',
    AVATAR: 'Awatar',
    NAME: 'Imię',
    DOMAIN: 'Domena',
    PHONE: 'Telefon',
    DESCRIPTION: 'Opis',
    FIRST_NAME: 'Imię',
    LAST_NAME: 'Nazwisko',
    EMAIL: 'E-mail',
    ROLE: 'Rola',
    COMPANY: 'Firma',
    PASSWORD: 'Hasło',
    PARENT: 'Kategoria nadrzędna',
    SERVICE: 'Usługa',
    COST: 'Koszt',
    START_DATE: 'Data rozpoczęcia',
    USERNAME: 'Nazwa użytkownika',
    END_DATE: 'Data zakończenia',
    CATEGORY: 'Kategoria',
    CLIENT_SEARCH: 'Szukaj klientów',
    NEXT: 'Dalej',
    SAVE_AND_NEXT: 'Następny krok',
    SUPPLIER: 'Dostawca',
    SKU: 'SKU',
    BRANCH: 'Oddział',
    COMMENT: 'Komentarz',
    ACTIVE: 'Aktywność',
    EMPLOYEES: 'Pracownicy',
    PERMISSIONS: 'Uprawnienia',
    GROUPS: 'Grupa uprawnień',
    ENABLED: 'Aktywność',
    CLIENTS: 'Klienci',
    CREATORS: 'Twórcy',
    ADDRESS: 'Adres',
    WORK_TIME_START: 'Początek pracy',
    WORK_TIME_END: 'Koniec pracy',
    NOTIFY: 'Powiadamiaj, gdy zamówienie...',
  },
  PLACEHOLDER: {
    USERNAME: 'Wprowadź nazwę użytkownika',
    NAME: 'Wprowadź nazwę',
    DOMAIN: 'Wprowadź domenę',
    PHONE: 'Wprowadź telefon',
    FIRST_NAME: 'Wprowadź imię',
    LAST_NAME: 'Wprowadź nazwisko',
    EMAIL: 'Wprowadź E-mail',
    ROLE: 'Wybierz rolę',
    COMPANY: 'Wybierz firmę',
    PASSWORD: 'Wprowadź hasło',
    DESCRIPTION: 'Wprowadź opis',
    SELECT: 'Wybierz',
    COST: 'Wprowadź koszt',
    QUANTITY: 'Wprowadź ilość',
    SKU: 'Wprowadź SKU',
    COMMENT: 'Wprowadź komentarz',
    ACTIVE: 'Aktywność',
    VALUE: 'Wprowadź wartość',
    CATEGORY: 'Kategoria',
    ADDRESS: 'Wprowadź adres',
    WORK_TIME_START: 'Początek pracy',
    WORK_TIME_END: 'Koniec pracy',
    PERIODICITY: 'Wprowadź okresowość',
    DISCOUNT: 'Wprowadź wysokość zniżki',
  },
  MODAL: {
    QR_CODE: {
      TITLE: 'Link do bota Telegram',
      COPY_LINK: 'Skopiuj link',
      FOOTER: 'Przekaż ten QR klientowi, aby mógł otrzymywać aktualizacje statusów zamówień i inne informacje.',
      FOOTER_DANGER: 'Tylko dla klientów! Nie używaj tego linku nawet do testów.',
    },
    DELETE: {
      TITLE: 'Potwierdzenie',
      DESC: 'Czy na pewno chcesz usunąć?',
    },
  },
  COMPANY: {
    TITLE: 'Lista firm',
    TITLE_NEW: 'Tworzenie firmy',
    TITLE_EDIT: 'Edytowanie firmy',
    RECEIPT_CONF: 'Ustawienia szablonu',
    NEW: 'Tworzenie',
    COMPANIES: 'Firmy',
    COMPANY_FIRST_OWNER: 'Pierwszy właściciel',
    COMPANY_CREDENTIALS: 'Dane',
  },
  COMPANY_USER: {
    TITLE: 'Użytkownicy',
    TITLE_NEW: 'Tworzenie użytkownika',
    TITLE_EDIT: 'Edytowanie użytkownika',
    NEW: 'Utwórz',
    USERS: 'Użytkownicy',
    USER_CREDENTIALS: 'Dane',
    ROLES: {
      ROLE_BUSINESS_ADMIN: 'Pracownik firmy',
      ROLE_BUSINESS_OWNER: 'Właściciel firmy',
    },
  },
  CLIENTS: {
    TITLE: 'Klienci',
    TITLE_NEW: 'Tworzenie klienta',
    TITLE_EDIT: 'Edytowanie klienta',
    RECEIPT_CONF: 'Konfiguracja paragonu',
    NAME: 'Imię',
    NEW: 'Utwórz',
    CREDENTIALS: 'Dane',
    CREATED: 'Data utworzenia',
  },
  EMPLOYEES: {
    TITLE: 'Pracownicy',
    TITLE_NEW: 'Tworzenie pracownika',
    TITLE_EDIT: 'Edytowanie pracownika',
    NEW: 'Utwórz',
    USERS: 'Imię i data rejestracji',
    USER_CREDENTIALS: 'Dane',
    ROLES: {
      ROLE_BUSINESS_ADMIN: 'Pracownik firmy',
      ROLE_BUSINESS_OWNER: 'Właściciel firmy',
    },
  },
  DASHBOARD: {
    LOGS_TITLE: 'Rejestr działań firmy',
    PLAN_CARD: {
      TITLE: 'Wykonanie planu',
      SET_PLAN: 'Ustaw plan dzienny',
      PLAN_VALUE: 'Wartość',
      OF: 'z',
    },
    ADMINS_STATISTICS: {
      TITLE: 'Statystyki pracowników',
      PROFILE: 'Profil',
      ADMINS: 'Pracownicy',
      SERVICES_AND_PRODUCTS: 'Usługi i produkty',
      ORDER_QTY: 'Liczba zamówień',
      ORDER_WITH_DISCOUNT: 'Łączna kwota',
    },
  },
  SERVICES_LIST: {
    TITLE: 'Usługi',
    TITLE_NEW: 'Tworzenie usługi',
    TITLE_EDIT: 'Edytowanie usługi',
    FORM_PRICE_TITLE: 'Koszt usługi',
    NEW: 'Utwórz',
    NAME: 'Nazwa',
    CATEGORY: 'Kategoria',
    TABLE_PRICE: 'Historia kosztów',
    COST: 'Koszt',
    NO_PRICE: 'brak kosztu',
    START_DATE: 'Data rozpoczęcia',
    END_DATE: 'Data zakończenia',
    PERIODICITY: 'Co każde',
    DISCOUNT_IS: 'Zniżka',
    ITEM: 'usługę',
    DISCOUNTS: 'Zniżki',
    DISCOUNT_HINT: 'Klient otrzymuje zniżkę na każdą usługę wielokrotną.',
    PERCENT_HINT: 'Określa wysokość zniżki.',
    REMOVE_DISCOUNT: 'Usuń zniżkę',
  },
  STOCK_ENTRY: {
    TITLE: 'Stan towarów',
    PRODUCT: 'Towar',
    QUANTITY: 'Ilość',
    WAREHOUSE: 'Magazyn',
    SOURCE: 'Źródło',
    NEW: 'Utwórz',
    TITLE_NEW: 'Wprowadzenie towaru',
    TITLE_EDIT: 'Edytowanie',
    RATE: 'Cena',
    PRODUCT_FROM: 'Źródło',
    SOURCE_SUPPLIER: 'Dostawca',
    SOURCE_WAREHOUSE: 'Magazyn',
  },
  PRODUCT_LIST: {
    TITLE: 'Towary',
    TITLE_NEW: 'Tworzenie towaru',
    TITLE_EDIT: 'Edytowanie towaru',
    FORM_PRICE_TITLE: 'Koszt towaru',
    NEW: 'Utwórz',
    NAME: 'Nazwa',
    CATEGORY: 'Kategoria',
    TABLE_PRICE: 'Historia kosztów',
    COST: 'Koszt',
    NO_PRICE: 'brak kosztu',
    START_DATE: 'Data rozpoczęcia',
    END_DATE: 'Data zakończenia',
  },
  SERVICES_CATEGORY: {
    TITLE: 'Kategorie usług',
    TITLE_NEW: 'Tworzenie kategorii',
    TITLE_EDIT: 'Edytowanie kategorii',
    NEW: 'Utwórz',
    NAME: 'Nazwa',
    PARENT: 'Kategoria nadrzędna',
  },
  SERVICES_PRICES: {
    TITLE: 'Ceny usług',
    TITLE_NEW: 'Nowa cena usługi',
    TITLE_EDIT: 'Edytowanie ceny usługi',
    NEW: 'Nowy',
    COST: 'Koszt',
    SERVICE: 'Usługa',
    START_DATE: 'Data rozpoczęcia',
    END_DATE: 'Data zakończenia',
  },
  PRODUCTS_LIST: {
    TITLE: 'Towary',
    TITLE_NEW: 'Tworzenie towaru',
    TITLE_EDIT: 'Edytowanie towaru',
    NEW: 'Utwórz',
    NAME: 'Nazwa',
    SUPPLIER: 'Dostawca',
    CATEGORY: 'Kategoria',
  },
  PRODUCTS_CATEGORY: {
    TITLE: 'Kategorie produktów',
    NAME: 'Nazwa',
    PARENT: 'Kategoria nadrzędna',
    NEW: 'Utwórz',
    TITLE_NEW: 'Tworzenie kategorii',
    TITLE_EDIT: 'Edytowanie kategorii',
  },
  PRODUCTS_SUPPLIER: {
    TITLE: 'Dostawcy towarów',
    NAME: 'Nazwa',
    NEW: 'Utwórz',
    TITLE_NEW: 'Tworzenie dostawcy',
    TITLE_EDIT: 'Edytowanie dostawcy',
  },
  BRANCHES: {
    TITLE: 'Oddziały',
    NAME: 'Nazwa',
    NEW: 'Utwórz',
    TITLE_NEW: 'Tworzenie oddziału',
    TITLE_EDIT: 'Edytowanie oddziału',
    INFO: 'Te informacje są używane w paragonach.',
  },
  SETTINGS: {
    TITLE_EDIT: 'Zmień ustawienia',
  },
  TRANSLATIONS: {
    TITLE: 'Tłumaczenia',
    KEY: 'Klucz',
    LOCALE: 'Lokalizacja',
    TEXT: 'Tekst',
    NEW: 'Utwórz',
    TITLE_NEW: 'Tworzenie tłumaczenia',
    TITLE_EDIT: 'Edytowanie tłumaczenia',
  },
  BOT: {
    TOKEN: 'Token',
    TITLE: 'Bot',
    NAME: 'Nazwa',
    NEW: 'Utwórz',
    TITLE_NEW: 'Tworzenie bota',
    TITLE_EDIT: 'Edytowanie bota',
    ACTIVE_BOT_MISSING_ERR: 'Brak aktywnego bota.',
    IN_PROGRESS: 'W trakcie',
    COMPLETE: 'Gotowe',
    CANCEL: 'Anulowane',
    STATUS_DESCRIPTION: 'Wybierz status, przy którym bot poinformuje klienta.',
    STATUS_PLACEHOLDER: 'Wybierz status',
  },
  ORDERS: {
    TITLE: 'Zamówienia',
    TITLE_NEW: 'Formularz zamówienia',
    NEW: 'Utwórz',
    NUMBER: 'Numer zamówienia',
    PHONE: 'Telefon',
    CLIENT_NAME: 'Imię klienta',
    TOTAL_COST: 'Kwota',
    FINAL_COST: 'Ze zniżkami',
    STATUS: 'Status',
    PREPAYMENT: 'Zapłacono',
    CLIENT: {
      TITLE: 'Określenie klienta zamówienia',
      CREATE_NEW_CLIENT: 'Utwórz klienta',
    },
    ASIDE: {
      CLIENT: 'Określenie klienta',
      CLIENT_DESC: 'Wprowadzenie informacji o kliencie',
      SETUP: 'Tworzenie zamówienia',
      SETUP_DESC: 'Określenie pozycji zamówienia',
      FILES: 'Pliki',
      FILES_DESC: 'Przesyłanie plików',
      SUMMARY: 'Podsumowanie zamówienia',
      SUMMARY_DESC: 'Sprawdzenie wprowadzonych informacji',
    },
    DEADLINE: 'Data gotowości',
  },
  ORDER: {
    BACK_TO_LIST: 'Powróć',
    TITLE_NEW: 'Tworzenie zamówienia',
    TITLE_EDIT: 'Edytowanie zamówienia',
    START_DATE: 'Data utworzenia',
    DUE_DATE: 'Zakończ',
    ITEMS: 'Pozycje',
    SERVICES: 'Usługi',
    PRODUCTS: 'Towary',
    PREPAYMENT: 'Zapłacono',
    RECEIPT: 'Paragon',
    PRINT_ALL: 'Drukuj oba',
    PRINT_MAIN: 'Drukuj główny',
    PRINT_SECONDARY: 'Drukuj wtórny',
    SETUP: {
      NAME: 'Nazwa',
      COST: 'Kwota',
      QUANTITY: 'Ilość',
      DISCOUNTS: 'Zniżki',
      TOTAL_COST: 'Łączna kwota',
      TABLE_TITLE: 'Pozycje zamówienia',
      ADD_SERVICE: 'Dodaj usługę',
      ADD_PRODUCT: 'Dodaj produkt',
      ITEMS: 'Pozycje zamówienia',
      ITEM: 'Pozycja zamówienia',
      OTHER_SERVICES_AND_PRODUCTS: 'Inne usługi i produkty',
      ADD_ITEM: 'Dodaj pozycję',
      DEFAULT_ITEM: 'Pozycja 1',
      ITEM_EDIT: 'Edytowanie pozycji',
      ITEM_NEW: 'Dodawanie pozycji',
      TOTAL: 'Kwota',
      COST_WITH_DISCOUNT: 'Ze zniżkami',
      SERVICES_LIST: 'Lista usług',
      PRODUCTS_LIST: 'Lista towarów',
    },
    DISCOUNT: {
      FORM_TITLE: 'Ustawienia zniżki',
      TYPE: 'Typ',
      VALUE: 'Wartość',
      ABSOLUTE: 'Absolutna',
      PERCENT: 'Procent',
    },
    FILES: {
      IMAGES: 'Obrazy',
      DOCS: 'Dokumenty',
    },
    STATUSES: {
      raw: 'Nowy',
      in_progress: 'W trakcie',
      cancel: 'Anulowane',
      complete: 'Zakończone',
      archived: 'Zarchiwizowane',
    },
    PAYMENT_STATUSES: {
      paid: 'Zapłacono',
      unpaid: 'Nie zapłacono',
      paid_partially: 'Zapłacono częściowo',
    },
    SUMMARY: {
      TITLE: 'Przegląd i potwierdzenie zamówienia',
      CLIENT_INFO: 'Klient',
      NAME: 'Imię',
      PHONE: 'Telefon',
      ORDER_DETAILS: 'Szczegóły zamówienia',
      SUMMARY: 'Podsumowanie',
      TOTAL_COST: 'Kwota',
      FINAL_COST: 'Ze zniżkami',
      COST: 'Kwota',
      DISCOUNTS: 'Zniżki',
      QUANTITY: 'Ilość',
      ORDER_WAREHOUSE: 'Magazyn zamówienia',
      COMMENT: 'Komentarz',
      ADD_DISCOUNT: 'Dodaj zniżkę',
    },
  },
  ROLES_EMPLOYEES: {
    TITLE: 'Grupy dostępu',
    NEW: 'Utwórz',
    NAME: 'Nazwa grupy',
    TITLE_NEW: 'Utwórz grupę',
    TITLE_EDIT: 'Edytuj grupę',
  },
  REPORTS: {
    NO_DATA: 'Dane nie znalezione. Skonfiguruj filtry, aby zawęzić wyniki raportu.',
    TOTAL: 'Razem',
    FILTERS: {
      START_DATE: 'Data od',
      END_DATE: 'Data do',
      BRANCHES: 'Oddziały',
      CLIENTS: 'Klienci',
      CREATORS: 'Twórcy',
      SERVICES_CATEGORY: 'Kategorie usług',
      SERVICES: 'Usługi',
      ORDER_STATUS: 'Statusy zamówienia',
      PAYMENT_STATUS: 'Statusy płatności',
      DATE_STEP: {
        TITLE: 'Okres',
        day: 'Po dniach',
        month: 'Po miesiącach',
        year: 'Po latach',
      },
    },
    SERVICE_SUMS_BY_ORDERS: {
      TITLE: 'Raport usług według zamówień',
      DESCRIPTION: 'Informacje o zamówieniu: daty, klient, liczba przedmiotów i usług. Koszty uwzględniają zniżki.',
      FINAL_COST: 'Kwota ze zniżkami',
    },
    NUM_SERVICES_BY_CATEGORY: {
      TITLE: 'Analiza sprzedaży (usługi)',
      DESCRIPTION: 'Raport dotyczący świadczonych usług za dzień, miesiąc lub rok. Koszty uwzględniają zniżki.',
    },
    NUM_SERVICES_BY_BRANCH: {
      TITLE: 'Analiza według oddziałów (usługi)',
      DESCRIPTION: 'Raport o usługach świadczonych w każdym oddziale. Koszty uwzględniają zniżki.',
    },
    SUMS_BY_ORDERS: {
      TITLE: 'Ogólny raport sprzedaży',
      DESCRIPTION: 'Koszt i liczba sprzedanych towarów i usług. Koszty podano z uwzględnieniem i bez uwzględnienia zniżek.',
    },
  },
  TRANSLATOR: {
    SELECT: 'Wybierz język interfejsu',
  },
  DATEPICKER: {
    NO_DATE_SELECTED: 'Data nie wybrana',
  },
  PAGE: {
    TRANSLATIONS: 'Tłumaczenia',
    COMMON: 'Ogólne',
    SETTINGS: 'Ustawienia',
    BOT: 'Bot',
    BOTS: 'Boty',
    COMPANIES: 'Firmy',
    COMPANY_USERS: 'Użytkownicy',
    ECOMMERCE_SEPARATE: 'Handel',
    COMPANY_SEPARATE: 'Firma',
    STOCK_SEPARATE: 'Magazyn',
    COMPANY_USER: 'Użytkownicy',
    LAYOUT_BUILDER: 'Kreator interfejsu',
    COMPANY: 'Firma',
    EMPLOYEES: 'Pracownicy',
    DASHBOARD: 'Panel wskaźników',
    ORDERS: 'Zamówienia',
    BRANCHES: 'Oddziały',
    CLIENTS: 'Klienci',
    PRODUCTS_GROUP: 'Towary',
    REPORTS_GROUP: 'Raporty',
    PRODUCTS_LIST: 'Lista towarów',
    PRODUCTS_CATEGORIES: 'Kategorie towarów',
    PRODUCTS_SUPPLIER: 'Dostawcy towarów',
    SERVICES_GROUP: 'Usługi',
    SERVICES_LIST: 'Lista usług',
    SERVICES_CATEGORIES: 'Kategorie usług',
    SERVICES_PRICES: 'Koszt',
    SERVICES_PRICES_EDIT: 'Edytowanie kosztu',
    STAFF: 'Zatrudnienie firmy',
    ROLES_EMPLOYEES: 'Grupy uprawnień',
    ROLES_EMPLOYEES_NEW: 'Tworzenie grupy uprawnień',
    ROLES_EMPLOYEES_EDIT: 'Edytowanie grupy uprawnień',
    TEMPLATES_NEW: 'Nowy szablon',
    TEMPLATES: 'Szablony',
    WAREHOUSES: 'Magazyny',
    SUPPLIERS: 'Dostawcy',
    STOCK_ENTRY: 'Wprowadzenie zapasów',
    BOT_EDIT: 'Edytowanie bota',
    BOT_NEW: 'Nowy bot',
  },
  AUTH: {
    SIGN_IN: 'Zaloguj się',
    SUBMIT: 'Potwierdź',
    FORGOT_PASS: 'Zapomniałeś hasła?',
    FORGOT_PASS_DESC: 'Wprowadź swój e-mail poniżej, aby zaktualizować hasło',
    GENERAL: {
      OR: 'Lub',
      SUBMIT_BUTTON: 'Potwierdź',
      NO_ACCOUNT: 'Nie masz konta?',
      SIGNUP_BUTTON: 'Zarejestruj się',
      FORGOT_BUTTON: 'Zapomniałem hasła',
      BACK_BUTTON: 'Wstecz',
      PRIVACY: 'Prywatność',
      LEGAL: 'Prawne',
      CONTACT: 'Kontakt',
    },
    LOGIN: {
      TITLE: 'Zaloguj konto',
      DESC: 'Wprowadź nazwę użytkownika i hasło',
      BUTTON: 'Zaloguj się',
    },
    LOGOUT: {
      BUTTON: 'Wyloguj',
    },
    RESET: {
      TITLE: 'Resetowanie hasła',
      DESC: 'Wprowadź swoje nowe hasło',
      PASS_CHANGED: 'Hasło zostało pomyślnie zmienione',
    },
    FORGOT: {
      TITLE: 'Zapomniałeś hasła?',
      DESC: 'Wprowadź swój e-mail, aby zresetować hasło',
      SUCCESS: 'Twoje konto zostało pomyślnie zresetowane.',
      REQUEST_SENT: 'Link do zresetowania hasła został wysłany na e-mail',
    },
    REGISTER: {
      TITLE: 'Zarejestruj się',
      DESC: 'Wprowadź swoje dane, aby utworzyć konto',
      SUCCESS: 'Twoje konto zostało pomyślnie zarejestrowane.',
    },
    VALIDATION: {
      INVALID: '{{name}} jest nieprawidłowy',
      REQUIRED: '{name} jest polem wymaganym',
      MIN_LENGTH: '{{name}} minimalna długość to {{min}}',
      AGREEMENT_REQUIRED: 'Akceptacja warunków jest wymagana',
      NOT_FOUND: 'Żądany {{name}} nie został znaleziony',
      INVALID_LOGIN: 'Dane logowania są nieprawidłowe',
      REQUIRED_FIELD: 'Pole wymagane',
      MIN_LENGTH_FIELD: 'Minimalna długość pola:',
      MAX_LENGTH_FIELD: 'Maksymalna długość pola:',
      INVALID_FIELD: 'Pole jest nieprawidłowe',
    },
  },
  TEMPLATES: {
    NEW: 'Utwórz',
    NAME: 'Nazwa',
    VARIABLES: 'Wartości',
    TITLE_NEW: 'Nowy szablon',
    ORDER_INVOICE_FOR_CUT: 'Szablon wtórnego paragonu',
    ORDER_INVOICE: 'Szablon głównego paragonu',
    PHONE_CONFIRMATION: 'Szablon potwierdzenia telefonu',
    ORDER_STATUS_IN_PROGRESS: 'Szablon "Zamówienie w trakcie"',
    ORDER_STATUS_COMPLETE: 'Szablon "Zamówienie zakończone"',
    ORDER_STATUS_CANCEL: 'Szablon "Zamówienie anulowane"',
  },
  STOCK: {
    TITLE: 'Magazyny',
    TITLE_EDIT: 'Edytowanie magazynu',
    TITLE_NEW: 'Tworzenie magazynu',
    DEFAULT: 'Magazyn domyślny',
    NEW: 'Nowy magazyn',
    PARENT: 'Nazwa nadrzędnego magazynu',
    NAME: 'Nazwa',
  },
}
